import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TransactionsService } from '../services/transactions.service';
import { WalletResponse } from '../models/response';

@Component({
  selector: 'app-reverse-transaction',
  templateUrl: './reverse-transaction.component.html',
  styleUrls: ['./reverse-transaction.component.css']
})
export class ReverseTransactionComponent
{
  isDropDownSelected: boolean = false;

  selectedReversalReason: string = "01";

  walletNumber!: string;
  reversalAmount!: string;

  constructor(private transactionService: TransactionsService, private toastr: ToastrService, private spinner: NgxSpinnerService)
  {
  }

  removeSpecialCharacters(event: any)
  {
    const pattern = /^\d*\.?\d*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
    }
  }

  setDropDown(){
    this.isDropDownSelected = !this.isDropDownSelected;
  }

  submitTransaction()
  {
    this.spinner.show();

    if(!this.walletNumber || !this.reversalAmount || !this.selectedReversalReason)
    {
      this.toastr.info("Please fill out all fields");
      this.spinner.hide();
      return;
    }

    if(!(/^\d+$/.test(this.walletNumber)))
    {
      this.toastr.error("Wallet number is not a number", "Validation Error");
      this.spinner.hide();
      return;
    }

    if(!(/^\d*\.?\d*$/.test(this.reversalAmount)))
    {
      this.toastr.error("Reversal amount is not a number", "Validation Error");
      this.spinner.hide();
      return;
    }

    this.transactionService.reverseTransaction(this.walletNumber, this.reversalAmount.toString(), this.selectedReversalReason).subscribe((response: WalletResponse) => {

      this.spinner.hide();

      if(response.errorCode != "0000000000")
      {
        this.toastr.error(response.errorDescription, "Transaction error");
      }
      else
      {
        this.toastr.success("Transaction success");
      }
      
      this.selectedReversalReason = "01";
      this.walletNumber = "";
      this.reversalAmount = "";
    });
  }
  
  setSelectedReversalReason(reversalOptionSelected: string)
  {
    this.selectedReversalReason = reversalOptionSelected;

    this.setDropDown();
  }

  getSelectedReversalReason()
  {
    if(this.selectedReversalReason == "01")
    {
      return "Fraud or Suspicious Activity";
    }
    else if(this.selectedReversalReason == "02")
    {
      return "Technical Related";
    }
    else if(this.selectedReversalReason == "03")
    {
      return "Sent to Incorrect Number";
    }
    else if(this.selectedReversalReason == "04")
    {
      return "Blocked eWallet";
    }
    else if(this.selectedReversalReason == "05")
    {
      return "Other";
    }

    return "";
  }
}