import { Injectable } from '@angular/core';
import { GetTransactions, GetTransactionsCount } from '../models/transactions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, of, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { WalletResponse } from '../models/response';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http: HttpClient, private toastr: ToastrService, private userAuthentication: AuthenticationService, private router: Router) { }

  getTransactionsCount(fromDate: string, toDate: string, selectedStatus: string): Observable<GetTransactionsCount>
  {
    var body = {
      StartDate: fromDate,
      EndDate: toDate,
      Status: selectedStatus
    };
    
    return this.http.post<GetTransactionsCount>(environment.ewalletAPI + environment.GetTransactionCount, body, { withCredentials: true }).pipe(
      catchError(this.handleError<GetTransactionsCount>('getTransactionsCount'))
    );
  }

  getTransactions(fromDate: string = "", toDate: string = "", status: string = "", pageNumber: string = ""): Observable<GetTransactions[]>
  {
    var body = {
      StartDate: fromDate,
      EndDate: toDate,
      PageNumber: pageNumber,
      Status: status
    };
    
    return this.http.post<GetTransactions[]>(environment.ewalletAPI + environment.GetTransactions, body, { withCredentials: true }).pipe(
      catchError(this.handleError<GetTransactions[]>('getTransactions', []))
    );
  }

  reverseTransaction(walletNumber: string, reversalAmount: string, reversalReason: string): Observable<WalletResponse>
  {
    var body = {
      UserName: this.userAuthentication.GetUsername(),
      WalletNumber: walletNumber,
      ReversalAmount: reversalAmount,
      ReversalReason: reversalReason
    }

    return this.http.post<WalletResponse>(environment.ewalletAPI + environment.ReverseTransactions, body, { withCredentials: true }).pipe(
      catchError(this.handleError<WalletResponse>('createTransaction'))
    );
  }

  downloadReport(fromDate: string, toDate: string, status: string, fileType: string): Observable<any>
  {
    let body = {
      startDate: fromDate,
      endDate: toDate,
      status: status,
      fileType: fileType,
      operatorName: environment.OperatorName
    };

    return this.http.post<any>(environment.ewalletAPI + environment.DownloadTransactionReport, body, { observe: 'response', responseType: 'blob' as 'json', withCredentials: true }).pipe(
      catchError(this.handleError<WalletResponse>('downloadReport'))
    );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if(error.status === 401)
      {
        this.toastr.info("Your session has expired");
        this.router.navigate(['login']);
      }
        
      return of(result as T);
    };
  }

}
