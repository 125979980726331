<div class="transactions-header">
        <img [src]="logo" class="logo">
        <img src="../../assets/Images/logout.png" class="log-out" (click)="Logout()">
</div>

<div class="transactions-tab">
    <p class="tab-options">
        <span class="tab-option" [ngStyle]="{'color': (this.selectedTab == 1) ? '#EEBA00' : 'gray', 'margin-right': '5%'}" (click)="ChangeTab(1)">Reversal History</span>
        <span class="tab-option" [ngStyle]="{'color': (this.selectedTab == 2) ? '#EEBA00' : 'gray'}" (click)="ChangeTab(2)">Reversal</span>
    </p>
</div>

<ng-container *ngIf="this.selectedTab == 1 else loading">
    <app-transaction-history></app-transaction-history>
</ng-container>

<ng-template #loading>
    <app-reverse-transaction></app-reverse-transaction>
</ng-template>