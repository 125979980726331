import { Component, OnInit } from '@angular/core';
import { TransactionsService } from '../services/transactions.service';
import { GetTransactions } from '../models/transactions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {
  isDropDownSelected: boolean = false;
  isExportDropDownSelected: boolean = false;

  selectedStatus: string = "";
  selectedExport: string = "CSV";

  allTransactions: GetTransactions[] = [];

  fromDate: string = "";
  toDate: string = "";

  fromDateFormatted: string = "";
  toDateFormatted: string = "";
  
  totalPages: number = 0;

  currentlySelectedPage: string = "0";

  isFilterEnabled: boolean = false;

  constructor(private transactions: TransactionsService, private toastr: ToastrService)
  {
    this.selectedStatus = "";
    this.fromDate = "";
    this.toDate = "";
  }

  ngOnInit()
  {
    this.getTransactions("0");
  }

  setDropDown(){
    this.isDropDownSelected = !this.isDropDownSelected;
  }

  setExportDropDown(){
    this.isExportDropDownSelected = !this.isExportDropDownSelected;
  }

  setSelectedStatus(optionSelected: string){
    this.selectedStatus = optionSelected;

    this.isDropDownSelected = false;
  }

  setSelectedExport(optionSelected: string){
    this.selectedExport = optionSelected;

    this.isExportDropDownSelected = false;
  }

  getSelectedStatus()
  {
    if(this.selectedStatus === "P")
    {
      return "Success";
    }
    else if(this.selectedStatus === "E")
    {
      return "Failed";
    }
    else if(this.selectedStatus === "Q")
    {
      return "Pending";
    }
    else
    {
      return "All";
    }
  }

  getSelectedExport()
  {
    if(this.selectedExport === "CSV")
    {
      return "CSV";
    }
    else if(this.selectedExport === "XL")
    {
      return "Excel";
    }
    else
    {
      return "";
    }
  }

  enableFilter()
  {
    this.isFilterEnabled = true;
  }

  getTransactions(pageNumber: string)
  {
    this.currentlySelectedPage = pageNumber;
    
    //Enabled when user clicks on filter icon
    if(this.isFilterEnabled)
    {
      if(!this.filterValidation())
      {
        return;
      }
    }

    this.getTransactionsCount(this.fromDateFormatted, this.toDateFormatted);

    this.getTransactionsList(this.fromDateFormatted, this.toDateFormatted);
  }

  getTransactionsCount(fromDate: string, toDate: string): void
  {
    this.transactions.getTransactionsCount(fromDate, toDate, this.selectedStatus).subscribe(res => {
      this.totalPages = Math.ceil(res.transactionCount / 10);
    });
  }

  getTransactionsList(fromDate: string, toDate: string): void
  {
    this.transactions.getTransactions(fromDate, toDate, this.selectedStatus, this.currentlySelectedPage).subscribe(res => {
      this.allTransactions = res;
    });
  }

  formatDate(transactionDate: string){
    var dateOnly = new Date(transactionDate);

    return dateOnly.toDateString() + " " + dateOnly.toLocaleTimeString();
  }

  formatCurrency(amount: string)
  {
    const convertAmountToNumber = Number(amount);

    const convertCentsToRands = convertAmountToNumber / 100;

    const amountFormat = new Intl.NumberFormat('en-ZA',{
      style: 'currency',
      currency: 'ZAR'
    }).format(convertCentsToRands);

    return amountFormat;
  }

  filterValidation() : boolean
  {
    //Disables filter is all filter fields are empty
    if(!this.fromDate && !this.toDate && !this.selectedStatus)
    {
      this.isFilterEnabled = false;
      this.toDateFormatted = "";
      this.fromDateFormatted = "";
      return true;
    }

    //Status field is only enabled
    if(!this.fromDate && !this.toDate && this.selectedStatus)
    {
      this.toDateFormatted = "";
      this.fromDateFormatted = "";
      return true;
    }

    if(!this.fromDate)
    {
      this.toastr.error("Please specify a start date", "Date Validation");
      return false;
    }

    var fromDateArray = this.fromDate.split("-");
    this.fromDateFormatted = fromDateArray[2] + "-" + fromDateArray[1] + "-" + fromDateArray[0];

    if(!this.toDate)
    {
      this.toastr.error("Please specify a end date", "Date Validation");
      return false;
    }

    var toDateArray = this.toDate.split("-");
    this.toDateFormatted = toDateArray[2] + "-" + toDateArray[1] + "-" + toDateArray[0];

    if(new Date(this.fromDateFormatted).toString() == "Invalid Date")
    {
      this.toastr.error("Invalid start date", "Date Validation");
      return false;
    }
    
    if(new Date(this.toDateFormatted).toString() == "Invalid Date")
    {
      this.toastr.error("Invalid end date", "Date Validation");
      return false;
    }

    if (isNaN(new Date(this.fromDateFormatted).getTime()))
    {
      this.toastr.error("Invalid start date", "Date Validation");
      return false;
    }

    if (isNaN(new Date(this.toDateFormatted).getTime()))
    {
      this.toastr.error("Invalid end date", "Date Validation");
      return false;
    }
    
    if(new Date(this.fromDateFormatted) > new Date(this.toDateFormatted))
    {
      this.toastr.error("Start date cannot be after the end date", "Date Validation");
      return false;
    }

    return true;
  }

  exportTransactions()
  {
    if(!this.filterValidation())
    {
      return;
    }
    
    this.transactions.downloadReport(this.fromDateFormatted, this.toDateFormatted, this.selectedStatus, this.selectedExport).subscribe({
      next: (data) => {
        let fileName = data.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
        fileName = fileName.substring(0, fileName.length - 1);
        let blob:Blob=data.body as Blob;
        let a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      },
      error: (error) => {

      }
    })
  }
}
