<div class="filter-section">
    <div class="input-field">
        <dp-date-picker [(ngModel)]="fromDate" [placeholder]="'From'" theme="dp-material"></dp-date-picker>
    </div>
    <div class="input-field">
        <dp-date-picker [(ngModel)]="toDate" [placeholder]="'To'" theme="dp-material"></dp-date-picker>
    </div>

    <div class="status-type-container" style="margin-top: 20px;">
        <div (click)="setDropDown()" class="status-type-title">
            <p>{{ getSelectedStatus() }}</p>
        </div>
        <div class="status-type-drop-down" [ngStyle]="{'height': (isDropDownSelected) ? '160px' : '0px'}">
            <div class="status-type-drop-down-child">
                <p (click)="setSelectedStatus('')" [ngStyle]="{'background-color': (selectedStatus == '') ? '#EEBA00' : 'transparent'}">All</p>
                <p (click)="setSelectedStatus('P')" [ngStyle]="{'background-color': (selectedStatus == 'P') ? '#EEBA00' : 'transparent'}">Success</p>
                <p (click)="setSelectedStatus('E')" [ngStyle]="{'background-color': (selectedStatus == 'E') ? '#EEBA00' : 'transparent'}">Failed</p>
                <p (click)="setSelectedStatus('Q')" [ngStyle]="{'background-color': (selectedStatus == 'Q') ? '#EEBA00' : 'transparent'}">Pending</p>
            </div>
        </div>
    </div>

    <div class="search-bar-container">
        <img src="../../assets/Images/search.png" (click)="enableFilter(); getTransactions('0');" />
    </div>

    <div class="status-description-container">
        <div class="status-description-child">
            <div class="status-shape status-success"></div>
            <p>Successful</p>
        </div>
        <div class="status-description-child">
            <div class="status-shape status-failed"></div>
            <p>Failed</p>
        </div>
        <div class="status-description-child">
            <div class="status-shape status-pending"></div>
            <p>Pending</p>
        </div>
    </div>

</div>

<div>
    <div class="status-type-container" style="margin-bottom: 15px; margin-right: 10px;">
        <div (click)="setExportDropDown()" class="status-type-title">
            <p>{{ getSelectedExport() }}</p>
        </div>
        <div class="status-type-drop-down" [ngStyle]="{'height': (isExportDropDownSelected) ? '90px' : '0px'}">
            <div class="status-type-drop-down-child">
                <p (click)="setSelectedExport('CSV')" [ngStyle]="{'background-color': (selectedExport == 'CSV') ? '#EEBA00' : 'transparent'}">CSV</p>
                <p (click)="setSelectedExport('XL')" [ngStyle]="{'background-color': (selectedExport == 'XL') ? '#EEBA00' : 'transparent'}">Excel</p>
            </div>
        </div>
    </div>
    <button type="submit" class="login-button" style="margin-top: 5px;" (click)="exportTransactions()">Export</button>
</div>

<div class="table-container">
    <table>
        <tr>
          <th>Initiated By</th>
          <th>Wallet Number</th>
          <th>Homing Reference</th>
          <th>Recon Reference</th>
          <th>Transaction ID</th>
          <th>Reversal Amount (ZAR)</th>
          <th>Reversal Reason</th>
          <th>Operator Name</th>
          <th>Date</th>
          <th>Status</th>
        </tr>
        <tr *ngFor="let transaction of allTransactions">
            <td>{{ transaction.userName }}</td>
            <td>{{ transaction.walletNumber }}</td>
            <td>{{ transaction.homingReference }}</td>
            <td>{{ transaction.reconReference }}</td>
            <td>{{ transaction.transactionId }}</td>
            <td>{{ formatCurrency(transaction.reversalAmount) }}</td>
            <td>{{ transaction.reversalReason }}</td>
            <td>{{ transaction.operatorName }}</td>
            <td>{{ formatDate(transaction.transactionDate) }}</td>
            <td><div class="status-description-child" [ngClass]="(transaction.status == 'E') ? ('failed-status') : (transaction.status == 'P') ? ('success-status') : (transaction.status == 'Q') ? ('pending-status') : ('unknown-status')"></div></td>
        </tr>
    </table>
</div>

<div class="pagination-container">
    <span class="pagination-count" *ngFor="let e of [].constructor(totalPages); let count = index" (click)="getTransactions((count).toString())" [ngStyle]="{color: (currentlySelectedPage == (count).toString()) ? '#9E7B00' : 'white', 'font-weight': (currentlySelectedPage == (count).toString()) ? '900' : 'normal'}"> 
        {{ count + 1 }}
    </span>
</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>