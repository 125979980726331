import { Component } from '@angular/core';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WalletResponse } from '../models/response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transactions-page',
  templateUrl: './transactions-page.component.html',
  styleUrls: ['./transactions-page.component.css']
})
export class TransactionsPageComponent {
  logo!: string;

  faSignOut = faSignOut;

  selectedTab: number = 1;

  constructor(private authenticationService: AuthenticationService, private router: Router, private toastr: ToastrService)
  {
    this.logo = environment.Logo;
  }

  ChangeTab(tab: number)
  {
    this.selectedTab = tab;
  }

  Logout()
  {
    this.authenticationService.Logout().subscribe((response: WalletResponse) => {
        if(!response.errorDescription)
        {
          this.toastr.info("Logged Out");
        }
    });
  }
}
