import { Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WalletResponse } from '../models/response';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {

  constructor(private authenticationService: AuthenticationService, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService)
  {
  }

  passwordValidation(password: string, confirmPassword: string) : boolean
  {
    if(!password && !confirmPassword)
    {
      this.toastr.error("Password fields are empty");
      return false;
    }

    if(password !== confirmPassword)
    {
      this.toastr.error("Password fields are not equal");
      return false;
    }

    if(password.length < 8 || confirmPassword.length < 8)
    {
      this.toastr.error("Password length is too short");
      return false;
    }

    if(!(/[a-zA-Z]/g).test(password) || !(/[a-zA-Z]/g).test(confirmPassword))
    {
      this.toastr.error("Passwords must contain at least one alphabet");
      return false;
    }
    
    if(!(/\d/).test(password) || !(/\d/).test(confirmPassword))
    {
      this.toastr.error("Passwords must contain at least one number");
      return false;
    }

    if(!(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(password) || !(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(confirmPassword))
    {
      this.toastr.error("Passwords must contain at least one special character");
      return false;
    }

    return true;
  }

  submitPasswordReset(password: string, confirmPassword: string)
  {
    if(this.passwordValidation(password, confirmPassword))
    {
      this.spinner.show();

      this.authenticationService.PasswordReset(password).subscribe((user: WalletResponse) => {

        if(user.errorDescription)
        {
          this.toastr.error(user.errorDescription, "Password Reset Failed");
        }
        else
        {
          this.toastr.success("Password Reset Success!");

          this.authenticationService.Logout();
        }

        this.spinner.hide();
      });
    }
  }
}
