<div class="form-styling">
    <div class="form-divisions">
        <p>Wallet Number</p>
        <input type="text" class="input-field" placeholder="Enter Wallet Number" [(ngModel)]="walletNumber"  />
        <p>Reversal Amount (ZAR)</p>
        <input type="text" class="input-field" id="reversal-amount" placeholder="Enter Reversal Amount" [(ngModel)]="reversalAmount" (input)="removeSpecialCharacters($event)" />
    </div>
    <div class="form-divisions">
        <div class="status-type-container">
            <p>Reversal Reason</p>
            <div (click)="setDropDown()" class="status-type-title input-field">
                <p>{{ getSelectedReversalReason() }}</p>
            </div>
            <div class="status-type-drop-down" [ngStyle]="{'height': (isDropDownSelected) ? '190px' : '0px'}">
                <div class="status-type-drop-down-child">
                    <p (click)="setSelectedReversalReason('01')" [ngStyle]="{'background-color': (selectedReversalReason == '01') ? '#EEBA00' : 'transparent'}">Fraud or Suspicious Activity</p>
                    <p (click)="setSelectedReversalReason('02')" [ngStyle]="{'background-color': (selectedReversalReason == '02') ? '#EEBA00' : 'transparent'}">Technical Related</p>
                    <p (click)="setSelectedReversalReason('03')" [ngStyle]="{'background-color': (selectedReversalReason == '03') ? '#EEBA00' : 'transparent'}">Sent to Incorrect Number</p>
                    <p (click)="setSelectedReversalReason('04')" [ngStyle]="{'background-color': (selectedReversalReason == '04') ? '#EEBA00' : 'transparent'}">Blocked eWallet</p>
                    <p (click)="setSelectedReversalReason('05')" [ngStyle]="{'background-color': (selectedReversalReason == '05') ? '#EEBA00' : 'transparent'}">Other</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="input-div-outer">
    <div class="login-container">
        <button type="submit" class="login-button" (click)="submitTransaction()">Submit</button>
    </div>
</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>