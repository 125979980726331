import { Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  logo!: string;

  viewPasswordToggle: boolean = false;
  passwordToggleState: string = "password";

  constructor(private authenticationService: AuthenticationService, private toastr: ToastrService, private spinner: NgxSpinnerService, private router: Router)
  {
    this.logo = environment.Logo;
  }

  togglePasswordView(): void
  {
    this.viewPasswordToggle = !this.viewPasswordToggle;
  }

  setPasswordViewState(): void
  {
    if(!this.viewPasswordToggle)
    {
      this.passwordToggleState = "password";
    }
    else
    {
      this.passwordToggleState = "text";
    }
  }

  login(username: string, password: string)
  {
    if(!username && !password)
    {
      this.toastr.info("Fields cannot be empty");
      return;
    }

    this.spinner.show();

    this.authenticationService.Authenticate(username, password).subscribe((user: User) => {

      if(user.errorDescription)
      {
        this.toastr.error(user.errorDescription, "Authentication Failed");
      }
      else
      {
        this.toastr.success("Authentication Success!");

        this.authenticationService.SetUsername(user.username);

        if(user.resetPassword)
        {
          this.router.navigate(['reset-password']);
        }
        else
        {
          this.router.navigate(['transactions-page']);
        }
      }
      this.spinner.hide();
    });
  }
}
