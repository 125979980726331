<div>
    <div class="input-div-outer">
        <div class="input-div-inner">
                <h3>Password <span class="asterisk">*</span></h3>
                <input type="password" class="input-field" #password>
                <h3>Confirm Password <span class="asterisk">*</span></h3>
                <input type="password" class="input-field" #confirmPassword>
                <div class="login-container">
                    <button type="submit" class="login-button" (click)="submitPasswordReset(password.value, confirmPassword.value)">Reset Password</button>
                </div>
        </div>
    </div>
</div>