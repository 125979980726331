import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { WalletResponse } from '../models/response';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private router: Router) { }

  Authenticate(username: string, password: string): Observable<User>{
    let httpHeaders = new HttpHeaders().set('Username', username).set('Password', password);

    return this.http.post<User>(environment.ewalletAPI + environment.Authentication, {}, { headers: httpHeaders, withCredentials: true });
  }

  Logout(): Observable<WalletResponse>{
    this.ResetUser();
    this.router.navigate(['login']);
    return this.http.post<WalletResponse>(environment.ewalletAPI + environment.Logout, { }, { withCredentials: true });
  }

  PasswordReset(password: string): Observable<WalletResponse>{

    let httpHeaders = new HttpHeaders().set('Password', password).set('UserName', this.GetUsername());

    return this.http.post<WalletResponse>(environment.ewalletAPI + environment.PasswordReset, {}, { headers: httpHeaders, withCredentials: true });
  }

  SetUsername(username: string): void
  {
    localStorage.setItem("ewallet_username", username);
  }

  GetUsername(): string
  {
    return localStorage.getItem("ewallet_username")!;
  }

  ResetUser()
  {
    localStorage.removeItem("ewallet_username");
  }
}
