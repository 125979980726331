<div>
    <div class="logo-div">
        <img [src]="logo" class="center-logo" />
    </div>
    <div class="input-div-outer">
        <div class="input-div-inner">
                <h3>Username <span class="asterisk">*</span></h3>
                <input type="search" class="input-field" #username>
                <h3>Password <span class="asterisk">*</span></h3>
                <div class="icon-div">
                    <i class="fa icon" [ngClass]="(!viewPasswordToggle) ? 'fa-eye' : 'fa-eye-slash'" (click)="togglePasswordView(); setPasswordViewState()"></i>
                </div>
                <input [type]="passwordToggleState" class="input-field" #password>
                <div class="login-container">
                    <button type="submit" class="login-button" (click)="login(username.value, password.value)">Login</button>
                </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>